import {venueslist} from '../../config/config' 
import {venuesdata} from '../../config/config' 
import {eventdata} from '../../config/config' 


export const venueslist_FETCH_STARTED = 'venueslist_FETCH_STARTED';
export const venueslist_FETCH_SUCCESS = 'venueslist_FETCH_SUCCESS';
export const venueslist_FETCH_ERROR = 'venueslist_FETCH_ERROR';


export const venuesdata_FETCH_STARTED = 'venuesdata_FETCH_STARTED';
export const venuesdata_FETCH_SUCCESS = 'venuesdata_FETCH_SUCCESS';
export const venuesdata_FETCH_ERROR = 'venuesdata_FETCH_ERROR';


export const eventdata_FETCH_STARTED = 'eventdata_FETCH_STARTED';
export const eventdata_FETCH_SUCCESS = 'eventdata_FETCH_SUCCESS';
export const eventdata_FETCH_ERROR = 'eventdata_FETCH_ERROR';

const actions = {

    venueslist: (data) => async (dispatch) => {    
      
        try {
          dispatch({
            type: venueslist_FETCH_STARTED,
          });
  
          const response = await venueslist(data);
          
          
  if(response){
    dispatch({
      type: venueslist_FETCH_SUCCESS,
      payload: {
          venues: response, 
      },
    });
  }
         return response
        } catch (error) {
          console.log(error)
          dispatch({
            type: venueslist_FETCH_ERROR,
          });
        }
    },

    venuesdata:(data)=>async(dispatch)=>{
      try {
        dispatch({
          type: venuesdata_FETCH_STARTED,
        });

        const response = await venuesdata(data);
       
if(response){
  dispatch({
    type: venuesdata_FETCH_SUCCESS,
    payload: {
      venuesdata: response, 
    },
  });
}
      return response
      } catch (error) {
        console.log(error)
        dispatch({
          type: venuesdata_FETCH_ERROR,
        });
      }
    },

    eventdata:(data)=>async(dispatch)=>{
      try {
        dispatch({
          type: eventdata_FETCH_STARTED,
        });

        const response = await eventdata(data);

        if(response) {
          dispatch({
            type: eventdata_FETCH_SUCCESS,
            payload: {
                eventdata: response, 
            },
          });
        }
return response
      
      } catch (error) {
        console.log(error)
        dispatch({
          type: eventdata_FETCH_ERROR,
        });
      }
    }


}

export default actions;