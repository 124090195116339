import {venueslist_FETCH_STARTED,venueslist_FETCH_SUCCESS,venueslist_FETCH_ERROR ,venuesdata_FETCH_STARTED ,venuesdata_FETCH_SUCCESS, venuesdata_FETCH_ERROR , eventdata_FETCH_STARTED ,eventdata_FETCH_ERROR ,eventdata_FETCH_SUCCESS} from "../actions/NewSelection";




const defaultState = {
  venues: [],
  venuesdata:[],
  eventdata:[],
  loading: false,
};

function NewSelectionReducers(state = defaultState, action) {
  switch (action.type) {
    case venueslist_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case venueslist_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        venues: action.payload
      });
    case venueslist_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        venues: [],
      });

      case venuesdata_FETCH_STARTED:
        return Object.assign({}, state, {
          ...state,
          loading: true,
        });
      case venuesdata_FETCH_SUCCESS:
        return Object.assign({}, state, {
          ...state,
          loading: false,
          venuesdata: action.payload
        });
      case venuesdata_FETCH_ERROR:
        return Object.assign({}, state, {
          ...state,
          loading: false,
          venuesdata: [],
        });


        case eventdata_FETCH_STARTED:
          return Object.assign({}, state, {
            ...state,
            loading: true,
          });
        case eventdata_FETCH_SUCCESS:
          return Object.assign({}, state, {
            ...state,
            loading: false,
            eventdata: action.payload.eventdata
          });
        case eventdata_FETCH_ERROR:
          return Object.assign({}, state, {
            ...state,
            loading: false,
            eventdata: [],
          });




    default:
      return state;
  }
}

export default  NewSelectionReducers;