import { fetchBookmakersAPIService } from '../../config/config'
import { toast } from 'react-toastify'

export const BOOK_MAKERS_FETCH_START = 'BOOK_MAKERS_FETCH_START';
export const BOOK_MAKER_FETCH_SUCCESS = 'BOOK_MAKER_FETCH_SUCCESS';
export const BOOK_MAKER_FETCH_ERROR = 'BOOK_MAKER_FETCH_ERROR';

function filterResponse(data) {
  const stringData = JSON.stringify(data).replace(/~/g, "'")
  return JSON.parse(stringData);
}

const ACTIONS = {
  // fetch all bookmakers data
  fetchBookmakersData: () => async (dispatch) => {
    try {

      dispatch({ type: BOOK_MAKERS_FETCH_START });
      const res = await fetchBookmakersAPIService();
      if (res.status === 200) {
        const rows = await filterResponse(res.data.data);
        rows.sort( (a,b) => a.ranking - b.ranking );
        dispatch({ type: BOOK_MAKER_FETCH_SUCCESS, payload: { data: rows } });
        return rows;
      } else {
        dispatch({ type: BOOK_MAKER_FETCH_ERROR });
        toast.error("Error in fetching Bookmaker List");
      }

    } catch (error) {
      dispatch({ type: BOOK_MAKER_FETCH_ERROR });
      toast.error('Error in fetching Bookmaker List' + error);
    }
  },
};




export default ACTIONS;
